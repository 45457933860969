import React from "react";
import ContactHero from "../ContactComponents/ContactHero";
import ContactMain from "../ContactComponents/ContactMain";
import AboutFAQ from "../AboutComponents/AboutFAQ";
import QuoteHero from "../QuoteComponents/QuoteHero";
import QuoteMain from "../QuoteComponents/QuoteMain";

export default function Quote() {
  return (
    <>
      <QuoteMain />
    </>
  );
}
