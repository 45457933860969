import React from "react";

export default function ContactHero() {
  return (
    <div className="bg-Hero epilogue">
      <div className="container py-hero-common ">
        <div className="d-flex justify-content-center flex-column align-items-center text-white">
          <h1>Contact </h1>
          <p>
            Welcome Folks! Be at ease, for we’ve got you covered. Scroll down
            and send us your queries.
            <br className="none-425" /> We’ll get back to you at the earliest
          </p>
        </div>
      </div>
    </div>
  );
}
