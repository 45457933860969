import React from 'react'
import PortfolioHero from '../PortfolioComponents/PortfolioHero'
import PortfolioMain from '../PortfolioComponents/PortfolioMain'

export default function Portfolio() {
  return (
<>
<PortfolioHero/>
<PortfolioMain/>

</>
  )
}
