import React from 'react'
import AboutHero from '../AboutComponents/AboutHero'
import AboutExperince from '../AboutComponents/AboutExperince'
import AboutTeam from '../AboutComponents/AboutTeam'
import AboutTestimonials from '../AboutComponents/AboutTestimonials'


export default function About() {
  return (
<>

<AboutHero/>
<AboutExperince/>
<AboutTeam/>
{/* <AboutTestimonials/> */}


</>
  )
}
